@import "../base/animations";
@import "../abstract/mixins";

.outer-container {
  h1 {
    padding: 0 3rem;

    @include respond(phone) {
      font-size: 3.3rem;
      padding: 0;
    }
  }
}

.signature-journeys-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
  position: relative;

  @include respond(phone) {
    padding: 0 2rem;
  }

  h1 {
    @include respond(phone) {
      // padding: 0;
    }
  }

  .sj-heading-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.sj-card-container {
  display: flex;
  justify-content: inherit;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  @include respond(tab-port) {
    align-items: center;
    flex-direction: column;
  }
}
