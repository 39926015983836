@import "../abstract/variables";

.book-now-container {
  display: flex;
  flex-direction: column;

  .container-part-1 {
    display: flex;
    flex: 5 5 50%;
    flex-direction: row;
    flex-wrap: wrap;

    @include respond(phone) {
      flex-wrap: wrap;
      // flex-direction: column;
    }

    // flex-grow: 1;
    height: 100%;

    .inputs-row-4 {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;

      @include respond(phone) {
        flex-wrap: wrap;
      }

      .input-container {
        @include respond(phone) {
          // padding: 0;
          // width: 50%;
        }
      }
    }

    .inputs-row-2 {
      display: flex;
      width: 100%;

      .input-container {
        @include respond(phone) {
          // padding: 0;
          // width: 50%;
        }
      }
    }

    .counter-field {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      height: 100%;
      width: 50%;
      text-transform: uppercase;
      margin-top: 1rem;

      p {
        font-family: RalewayBold;
        color: $color-black;
      }

      div {
        margin-top: 1rem;
      }

      .button-round {
        font-size: 1.7rem;
        width: 3rem;
        height: 3rem;
        border-width: 0;
        border-radius: 50%;
      }
    }
  }

  .container-part-2 {
    flex: 5 5 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    padding: 0 2rem;
    margin-top: 2rem;

    .checkbox-row {
      flex: 5 5 50%;
      align-items: center;

      // padding: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;

      @include respond(phone) {
        flex: unset;
        justify-content: space-between;
        line-height: normal;
      }
      p {
        font-size: 1.3rem;
        margin-left: 1rem;

        @include respond(phone) {
          width: 100%;
        }
      }
    }

    .revert-row {
      // padding: 3rem;
      align-self: flex-start;
      align-items: center;
      width: 100%;
      flex: 5 5 50%;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        flex: unset;
        margin-top: 1rem;
        padding: 0;
      }

      p {
        font-size: 1.3rem;
      }

      ul {
        list-style: none;
        color: $color-pink;
        font-size: 1.5rem;
        font-family: TrebuchetBold;
        margin-top: 1rem;

        li {
          margin-top: 1rem;

          font-size: 1.2rem;
          font-family: TrebuchetBold;
          color: $color-grey-dark;
        }
      }
    }
  }
}

.noHover {
  pointer-events: none;
}

.confirm-box-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  font-size: 3rem;
  font-weight: 800;
  color: $color-grey-dark;
  text-align: center;
  font-family: FuturaBook;
  padding: 5rem;

  @include respond(tab-port) {
    padding: 2rem;
    font-size: 2rem;
  }
}
