@import "../abstract/variables";
@import "../abstract/mixins";

.testimonials-heading {
  padding: 0 3rem;

  @include respond(phone) {
    padding: 0;
  }
}

.testimonials-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5rem;

  @include respond(tab-port) {
    flex-direction: column-reverse;
  }

  .testimonial-left {
    min-height: 60vh;
    flex: 0.5;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 3rem;

    @include respond(tab-port) {
      padding: 0 2rem;
      min-height: 30vh;
    }

    h2 {
      font-size: 1.5rem;
      color: $color-black;
      font-family: RalewayBold;
    }

    h3 {
      font-size: 1.5rem;
      color: $color-black;
    }

    .testimonial-details-container {
      .testi-comma {
        height: 15%;
        width: 10%;

        img {
          height: 0%;
          width: 0;
        }
      }
    }

    p {
      line-height: normal;
      margin-top: 2rem;
      width: 70%;
      font-family: RalewayMedium;
      font-size: 1.6rem;
      color: #000;

      @include respond(tab-port) {
        width: 100%;
      }
    }

    .testimonials-arrow-container {
      display: flex;
      margin-bottom: 2rem;
      justify-content: space-between;
      // border: 1px solid red;

      @include respond(tab-port) {
        margin: 3rem 0;
      }

      @include respond(phone) {
      }
    }
  }

  .testimonial-right {
    min-height: 60vh;
    flex: 0.5;
    background-position: top;
    background-size: cover;
    padding: 0 3rem;

    @include respond(tab-port) {
      min-height: 30vh;
    }

    @include respond(phone) {
      padding: 0 2rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
