@import "../abstract/mixins";
@import "../abstract/variables";

.ethos-container {
  display: flex;
  flex-direction: column;
  // width: 100%;
  padding: 3rem;

  @include respond(phone) {
    padding: 0;
  }

  &-cards {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    @include respond(tab-port) {
      margin-top: -1rem;
    }

    .ethos-column {
      flex: 2.5 2.5 25%;
      display: flex;
      flex-direction: column;
      padding: 0 2rem 0 0;

      @include respond(phone) {
        margin-bottom: 2rem;
        padding: 0 2rem;
      }

      .wander {
        text-transform: uppercase;
        font-family: TrebuchetRegular;
        margin-top: 1rem;
        font-size: 1.2rem;

        @include respond(tab-port) {
          margin-top: 0.5rem;
        }

        @include respond(phone) {
          margin-top: 0;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 1rem 3rem 0 0;

        @include respond(tab-port) {
          // margin: 2rem;
          padding: 1rem;
        }

        @include respond(phone) {
          margin: 0 0 1rem 0;
          //   margin: -1rem 0;
        }

        &-heading {
          font-family: FuturaHeavy;
          text-transform: uppercase;
          color: $color-black;
          font-size: 1.1rem;
          margin-top: 0.5rem;
        }

        &-description {
          font-family: FuturaBook;
          margin-top: 1rem;

          @include respond(phone) {
            margin-top: 0;
            width: 100%;
          }

          color: $color-black;
          line-height: normal;
          width: 75%;
          font-size: 1rem;
        }
      }

      img {
        border-radius: 1rem;
        width: 100%;
        // padding: 0 1rem;
        height: 30vh;

        @include respond(phone) {
          width: 100%;
          height: 43vh;
          // padding: 1rem;
        }

        object-fit: cover;
        object-position: top;
        // height: 55%;
      }
    }

    @include respond(phone) {
      flex-direction: column;
    }
  }

  button {
    @include respond(phone) {
      margin-top: 0;
    }
  }
}
