@import "../abstract/variables";

.faq-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  .faq-big-heading {
    font-size: 9rem;
    align-self: center;
    color: $color-pink;

    @include respond(tab-land) {
      font-size: 8rem;
    }
    @include respond(tab-port) {
      font-size: 7rem;
    }

    @include respond(phone) {
      font-size: 4rem;
      line-height: normal;
    }

    // margin: 0 auto;
  }

  .faq-question-heading {
    text-transform: "initial";
    margin-top: 2rem;
    // margin-bottom: 20px;

    font-size: 3rem;

    @include respond(phone) {
      font-size: 2.5rem;
      line-height: normal;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .faq-list {
    display: flex;

    p {
      font-size: 1.5rem;

      @include respond(tab-port) {
        font-size: 1.2rem;
      }

      font-weight: 500;
      margin: 0px 0px 20px 0px;
      font-family: RalewayMedium;
      color: $color-black;
      line-height: normal;
    }

    .faq-list-item {
      display: flex;

      margin: 20px 0px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      flex: 1;
      // flex: 1 1 1;
      // flex: 5 5 50%;
      padding-right: 15px;
    }

    &__right {
      display: flex;
      flex-direction: column;
      // flex: 5 5 50%;
      // flex: 1 1 1;
      // padding-left: 15px;
    }
  }
}
