// MEDIA QUERY MANAGER

/*
    0-600px: Phone
    600-900px: Tablet
    900-1200px: Tablet landscape
    1200px-1800px: Normal styles apply
    1800px + : Desktop

    $breakpoint argument choices 
    - phone
    - tab-port
    - tab-land
    - desktop

    1em = 16px
    */

@mixin respond($breakpoint) {
  @if ($breakpoint ==phone) {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if ($breakpoint ==tab-port) {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if ($breakpoint ==tab-land) {
    @media (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if ($breakpoint ==desktop) {
    @media (max-width: 112.5em) {
      border-width: 1px solid red;
      //1800px
      @content;
    }
  }
}

@mixin rowSpacer($margin) {
  margin-right: $margin;

  &:last-child {
    margin-right: 0;
  }
}
