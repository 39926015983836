@import "../base/animations";
@import "../abstract/mixins";

.destination-container {
  display: flex;
  flex-direction: column;
  transition: all ease 0.3s;

  // height: 95vh;

  &-left {
    display: flex;
    flex-direction: column;
    flex: 0;
    height: 100%;

    &-upper {
      flex: 1;
      position: relative;

      h1 {
        font-family: TrebuchetBold;
        font-size: 11.5rem;

        @include respond(tab-port) {
          font-size: 7rem;
        }

        @include respond(phone) {
          font-size: 3rem;
          position: absolute;
          top: 10%;
          z-index: 1000;
        }

        font-weight: 800;
        color: #d493a1;
        text-transform: uppercase;
        line-height: 1;
        overflow: hidden;
        animation-name: moveInBottomLong;
        animation-duration: 0.6s;
      }

      p {
        font-size: 2.3rem;
        // color: #d493a1;
        // text-transform: uppercase;
        // line-height: 1;
        overflow: hidden;
        animation-name: moveInBottomLong;
        animation-duration: 0.6s;
        margin: 1rem 1rem 1rem 1rem;
        // margin-top: 10rem;
      }
    }

    &-lower {
      flex: 0.7;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      height: 100%;

      p {
        margin: 3rem;
        font-family: RalewayMedium;
        line-height: 1.5rem;
        color: black;
      }

      button {
        margin-top: 1rem;
        margin-left: 3rem;
      }
    }
  }

  &-right {
    // flex: 5 5 50%;

    &-destination-image {
      margin-top: 2rem;

      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }
  }
}

.destination-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0;

  & > *:nth-child(n + 5) {
    margin-top: 2rem;

    @include respond(tab-port) {
      margin-top: 0.6rem;
    }
  }

  @include respond(tab-port) {
    // margin-top: 2rem;
    padding: 3rem;
  }
}
