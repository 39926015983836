@keyframes moveInLeft {

    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    70% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    70% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }

}

@keyframes moveInBottom {
    0% {
        opacity: 0.5;
        transform: translateY(2rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveInTop {
    0% {
        opacity: 0.5;
        transform: translateY(-2rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveInBottomLong {
    0% {
        opacity: 0.5;
        transform: translateY(20rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveSlow {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.01);
        /* Adjust this value for more or less zoom */
    }

}