@import "../abstract/mixins";
@import "../abstract/variables";

.footer {
  width: 100%;
  font-size: 1.3rem;
  background-color: #666;
  color: #fff;
  display: flex;
  padding: 3rem;

  @include respond(tab-port) {
    padding: 0;
  }

  font-family: RalewayRegular;
  justify-content: space-between;

  @include respond(tab-port) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  &-section {
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      width: 50%;
      padding: 1rem;
    }

    &-label {
      font-size: 1.5rem;
      color: #d1bdaf;
      font-family: RalewayBold;
    }

    &-links {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;

      a {
        text-decoration: none;
        color: #fff;
        transition: all 0.3s;

        &:hover {
          transition: all 0.3s;
          transform: translateY(-3px);
        }
      }
    }
  }

  &-left {
    width: 100%;
    display: flex;
    flex-direction: column;

    flex: 0.5;

    @include respond(tab-port) {
      width: 100%;
    }

    &-up {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // border: 1px solid;
    }

    &-down {
      margin-top: 5rem;
      width: 400px;

      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &-right {
    margin-left: 4rem;
    flex: 0.5;
    display: flex;
    justify-content: space-around;
    width: 100%;

    @include respond(tab-port) {
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 2rem;
      margin-left: 0;
    }

    .footer-section {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        width: 40%;

        @include respond(phone) {
          margin-left: 0;
          width: unset;
        }

        // align-items: center;
      }

      &-links {
        justify-content: space-between;
      }

      .tnc {
        align-self: flex-end;

        @include respond(phone) {
          align-self: unset;
        }
      }
    }

    .logo {
      object {
        align-self: flex-end;
        height: 6rem;

        @include respond(phone) {
          margin-top: 2rem;
          align-self: flex-start;
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-content {
        display: flex;
        justify-content: space-between;
      }

      label {
        padding: 1rem;
        font-family: RalewayBold;
        margin-bottom: 0.5rem;
        border: 1px solid #a5a5a5;
        border-radius: 0.5rem;
      }

      input {
        color: rgba(200, 200, 198, 1);
        background: none;
        outline-width: 0;
        border: none;
        border: 1px solid #a5a5a5;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        padding: 1rem;
        flex: 1;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &-copyright {
    text-align: end;

    @include respond(phone) {
      text-align: start;
    }

    margin-top: 9rem;
    font-size: 1rem;

    div {
      color: $color-grey;
    }
  }

  &-button {
    border-radius: 0.3rem;
    text-align: center;
    border: none;
    color: #fff;
    text-transform: uppercase;
    width: 18rem;
    height: 4rem;
    padding: 1rem;
    background-color: #97789b;
    font-weight: 1000;
    font-size: 1.2rem;
  }
}