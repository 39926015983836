@import "../abstract/variables";
@import "../abstract/mixins";

body {
  font-family: RalewayRegular;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark;
  box-sizing: border-box;
}

h1 {
  font-family: TrebuchetRegular;
  letter-spacing: 0.1rem;
  font-weight: 1000;
  color: #97789b;
  text-transform: initial;
  // padding: 0 3rem;
  font-size: 3.5rem;

  // @include respond(tab-port) {
  //   font-size: 2.3rem;
  // }

  // @include respond(tab-land) {
  //   font-size: 2.4rem;
  // }

  @include respond(phone) {
    // font-size: 2.5rem;
    margin: 2rem 2rem;
  }
}

.jr-card {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #b3c8d3;
  font-family: TrebuchetRegular;
  transition: all ease 0.3s;
  margin: 0.5rem 0;
  color: #fff;

  @include respond(tab-port) {
    width: 70%;
  }

  @include respond(phone) {
    width: 100%;
  }

  width: 20vw;

  &-upper {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 22rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-position: bottom;

    button {
      align-self: center;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }

  &-image {
    height: 22rem;
    width: 100%;
    border: 1px solid red;
    background-size: cover;
    background-position: bottom;
    border-radius: 0.5rem;
  }

  &-lower {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2rem;
    cursor: pointer;

    &-heading {
      font-size: 2rem;
      line-height: 1.3;
      font-weight: 800;
      // text-transform: uppercase;

      &-sub {
        font-size: 1.3rem;
        font-weight: 800;
        width: 100%;

        &-about {
          // text-align: center;
          // border: 1px solid red;
        }
      }

      &-description {
        font-size: 1.3rem;
        font-weight: 800;
        line-height: 1;
      }
    }

    &-arrow {
      margin-top: 1.4rem;
      width: 3.4rem;
    }
  }

  background-color: #97789b;
}

.jr-card:hover {
  color: #fff;
  // cursor: pointer;
  transition: all 0.3s;
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  background-color: #b3c8d3;
}

.jr-card:active {
  // background-color: #b3c8d3;
  transition: all 0.3s;
  transform: translateY(1px);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid $color-grey-dark;
  align-content: center;

  width: 10rem;
  height: 4rem;

  padding: 1rem;
  text-align: center;
  position: relative;

  .dropdown-content {
    position: absolute;
    top: 4rem;
    right: 0;

    ul {
      animation-name: moveInTop;
      animation-duration: 0.2s;
      transition: all ease-in 0.2s;

      li {
        border-radius: 5%;
        padding: 1rem;
        animation-duration: 0.2s;
        transition: all ease-in 0.2s;
        animation-name: moveInTop;
        height: 4rem;
        border: 0.5px solid $color-grey-dark;
        // margin:0.5rem;
        background-color: white;
      }

      list-style: none;
    }
  }
}

.diamond {
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border: 0.8px solid black;
  // transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    font-weight: 1000;
    transform: translateY(-3px), rotate(0deg);
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
  }
}

.rotate {
  transform: rotate(45deg);
}

.rotate-back {
  transform: rotate(-180deg);
}

// .arrow-container {
//     position: relative;
//     width: 2.5rem;
//     height: 2.5rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 1rem;
//     border: 1px solid red;
// }

.arrow {
  // width: 2.5rem;
  // height: 0.7px;
  // background-color: black;
  // position: absolute;
  // transform: rotate(-45deg);
  // background-clip: content-box;

  width: 2.5rem;
  height: 0.7px;
  background-color: black;
  position: relative;
  transform: rotate(-45deg);
  transition: all 0.1s ease;

  &:hover {
    transition: all 0.1s;
    // transform: rotate(45deg);
  }
}

.arrow-before {
  position: absolute;
  top: -4.8px;
  right: 1.5px;
  width: 1rem;
  height: 1rem;

  transform: rotate(45deg);
  box-sizing: border-box;
}

.modal {
  // height: 100%;
  display: flex;
  padding: 0;
  width: 50vw;

  @include respond(phone) {
    width: unset;
    flex-direction: column;
  }

  &-left {
    flex: 0.5;
    border-radius: 0.5rem;
    // background-repeat: no-repeat;
    // height: 30vh;

    &-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-right {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 0 2rem;
    min-height: 30vh;
    // height: 30vh;

    @include respond(phone) {
      padding: 3rem 0;
    }

    .jr-card-lower-heading {
      font-size: 2rem;
    }

    &-description {
      font-size: 1.5rem;

      h1 {
        font-size: 2.5rem;
        margin: 3rem 0;
        color: #fff;
      }

      @include respond(phone) {
        font-size: 1.4rem;
      }
      margin-top: 1rem;
      line-height: normal;
    }
  }
}
