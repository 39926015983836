@import "../abstract/mixins";
@import "../abstract/variables";

.product-main-container {
  @include respond(phone) {
    text-align: left;
  }

  .product-container-part-1 {
    display: flex;
    justify-content: space-between;

    @include respond(tab-port) {
      flex-direction: column;
    }

    &-left {
      height: 80vh;
      flex: 5 5 50%;

      &-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &-right {
      height: 80vh;
      flex: 5 5 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 2rem;

      @include respond(tab-port) {
        padding: 2rem;
        margin-left: 0;
      }

      &-heading {
        width: 90%;

        @include respond(tab-port) {
          width: unset;
        }
      }

      h1 {
        color: $color-black;
        text-transform: initial;
        line-height: 6rem;
        font-size: 6rem;

        @include respond(tab-land) {
          line-height: normal;
          font-size: 5.5rem;
        }

        @include respond(tab-port) {
          font-size: 5rem;
        }

        @include respond(phone) {
          font-size: 4rem;
          margin: 0;
          width: 100%;
        }
      }

      &-row1 {
        flex-wrap: wrap;
      }

      &-row1,
      &-row2 {
        margin-top: 2rem;
        display: flex;
        // flex: 1;
        justify-content: space-between;

        ul {
          list-style: none;
          font-weight: 1000;
          color: $color-pink;
          font-size: 2rem;
          flex: 1;
          display: flex;
          flex-direction: column;

          @include respond(phone) {
            flex: unset;
            flex-wrap: wrap;
          }

          h1 {
            font-size: 1.5rem;
            padding: initial;

            @include respond(phone) {
              font-size: 1.3rem;
              line-height: inherit;
              margin: 1rem 0;
            }

            text-transform: uppercase;
            color: $color-pink;
          }

          li {
            color: $color-black;
            font-size: 1.3rem;
            padding: 0.1rem 0.1rem;
            // line-height: 1.5;
          }

          button {
            margin: 1.5rem 0;
          }
        }
      }
    }
  }

  .product-container-part-2 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    &-row1 {
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        flex-direction: column;
        text-align: left;
      }

      margin: 3rem 0;
      flex: 3.4 3.4 34%;

      .product-image {
        width: calc(25% - 1rem);
        height: 40vh;
        object-fit: cover;
        object-position: top;

        @include rowSpacer(1rem);

        /* Responsive adjustments */
        @include respond(phone) {
          padding: 2rem;
          width: 100%;
        }
      }
    }

    &-row2,
    &-row3 {
      flex: 3.4 3.4 34%;
      display: flex;
      // margin: 3rem 0;

      @include respond(phone) {
        flex-direction: column;
        text-align: left;
        margin: 0;
      }

      .list-small-text {
        flex: 2.5 2.5 25%;

        @include rowSpacer(1rem);

        // padding: 0 1rem;

        @include respond(tab-port) {
          padding: 0 0.5rem;
        }

        @include respond(phone) {
          padding: 2rem;
        }

        list-style: none;
        font-family: "Inter", sans-serif;

        li {
          margin: 1rem 0;
          color: $color-black;

          .list-small-text-heading-coloured {
            color: $color-pink;
            font-size: 3rem;
            line-height: normal;
          }

          h2 {
            color: #000;
            text-transform: uppercase;
            font-family: TrebuchetBold;
            font-size: 2rem;
          }

          p {
            font-size: 1.3rem;
          }
        }
      }

      .bold {
        color: #000;
        font-size: 1.5rem;
      }
    }
  }

  .product-container-outro {
    position: relative;
    line-height: normal;

    margin: 3rem 0;

    &-heading {
      position: absolute;
      text-align: center;
      top: 50%;
      width: 70%;

      @include respond(phone) {
        width: 100%;
      }
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 90vh;
    }

    h2 {
      font-family: TrebuchetBold;
      font-size: 6rem;
      color: $color-white;
      text-transform: uppercase;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }
}
