@import "../abstract/mixins";

.vision-heading {
  text-transform: uppercase;
  margin: 3rem 0 1rem 3rem;

  @include respond(tab-port) {
    margin: 0;
    padding: 2rem;
  }
}

.about-us-heading {
  font-size: 8rem;

  @include respond(tab-land) {
    font-size: 7.5rem;
  }

  @include respond(tab-port) {
    font-size: 7rem;
  }

  @include respond(phone) {
    font-size: 5.75rem;
  }
}

.about-us-container {
  display: flex;
  flex-direction: row;

  @include respond(tab-port) {
    flex-direction: column;
  }

  @include respond(phone) {
    font-size: 1.8rem;
  }

  margin: 4rem 0 3rem 0;
  //   max-height: 60vh;

  // padding: 3rem;
  // height: 95vh;
  .about-us-leftSide,
  .about-us-rightSide {
    flex: 5 5 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .about-us-leftSide {
    margin-right: 2rem;

    @include respond(tab-port) {
      padding: 0 2rem;
      margin-right: 0;
      // margin-right: 0;
    }

    &-heading {
      @include respond(tab-port) {
        margin: 0;
      }

      // margin: 0 2rem;
    }

    p {
      flex: 4 4 40%;
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-size: 2.5rem;

      @include respond(tab-port) {
        font-size: 2rem;
      }

      @include respond(phone) {
        font-size: 1.8rem;
        width: 100%;
      }

      width: 75%;
      line-height: normal;
      color: $color-black;
    }

    &-img {
      flex: 6 6 60%;
      max-width: 100%;
      max-height: 50rem;
      object-fit: cover;
      object-position: 50% 40%;
    }
  }

  .about-us-rightSide {
    margin-left: 2rem;

    @include respond(tab-port) {
      padding: 0 2rem;
      margin-top: 2rem;
      margin-left: 0;
      // margin-right: 0;
    }

    &-heading {
      @include respond(tab-port) {
        margin: 4rem 0;
      }
    }

    p {
      flex: 4 4 40%;
      margin-top: 3rem;
      font-size: 2.5rem;
      width: 75%;
      line-height: normal;
      color: $color-black;

      @include respond(tab-port) {
        font-size: 2rem;
        margin-top: -2rem;
      }

      @include respond(phone) {
        font-size: 1.8rem;
        width: 100%;
      }
    }

    &-img {
      flex: 6 6 60%;
      width: 100%;
      max-width: 100%;
      max-height: 50rem;
      object-fit: cover;
    }
  }
}

.about-us-gifs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 5rem 0;

  @include respond(phone) {
    flex-direction: column;
  }

  .about-us-gif-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;

    &:first-child {
      align-items: flex-start;

      @include respond(phone) {
        align-items: center;
      }
    }

    &:last-child {
      align-items: last baseline;

      @include respond(phone) {
        align-items: center;
      }
    }

    @include respond(tab-port) {
      width: 100%;
      align-items: center;
      margin: 2rem 0;
    }

    img {
      width: 50%;
      height: 50%;
    }

    h2 {
      //   align-self: flex-start;
      font-size: 2rem;
      color: $color-black;
      text-transform: uppercase;
      font-family: TrebuchetBold;
    }

    p {
      font-size: 1.4rem;
      width: 70%;
    }
  }
}

.wwa-container {
  display: flex;
  flex-direction: row;
  //   height: 90vh;

  @include respond(tab-port) {
    flex-direction: column;
    height: 100%;
  }

  &-left {
    display: flex;
    flex-direction: column;
    flex: 5 5 50%;
    justify-content: space-between;
    padding: 3rem;

    @include respond(tab-port) {
      padding: 2rem;
    }

    &-inner {
      p {
        color: #000;
        font-size: 1.7rem;

        @include respond(phone) {
          font-size: 1.5rem;
        }

        // margin: 3rem 0;
      }
    }

    &-images {
      display: flex;
      flex-direction: row;

      @include respond(phone) {
        flex-direction: column;
        flex-wrap: wrap;
      }

      justify-content: space-between;
    }

    img {
      height: 55vh;
      flex: 0.475;

      @include respond(phone) {
        height: 5vh;
        margin: 2rem 0;
        width: 100%;
      }

      border-radius: 0.5rem;
    }
  }

  &-right {
    margin: 4rem 0rem 3rem 0rem;
    flex: 5 5 50%;
    background-size: cover;
    background-position: bottom;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
      font-size: 2rem;
      color: $color-black;
    }
  }
}

.founders-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(229, 229, 225);

  @include respond(tab-port) {
    height: 100%;
    // text-align: center;
  }

  &-heading {
    font-size: 5rem;
    text-transform: uppercase;
    color: rgb(70, 70, 68);
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  &-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond(tab-port) {
      flex-direction: column;
    }

    &-founder {
      flex: 0.25;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 3rem;

      h3 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.8rem;
        color: $color-black;
      }

      p {
        font-size: 1.4rem;
        margin-top: 1rem;
      }
    }

    &-images {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0.5;

      @include respond(tab-port) {
        flex-direction: column;
      }

      .founders-image {
        padding: 2rem;
        width: 50%;
        height: 80%;
        object-fit: cover;

        @include respond(tab-port) {
          height: 50vh;
          border-radius: 0.3rem;
          object-fit: cover;
        }

        @include respond(phone) {
          height: 30vh;
        }
      }
    }
  }
}

.mission-content-container {
  margin: 3rem 0;

  .mission-content-heading {
    font-size: 5rem;
    line-height: normal;

    @include respond(tab-port) {
      font-size: 4rem;
    }

    @include respond(phone) {
      font-size: 3.5rem;
    }
  }
}

.about-us-mission-row {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0;

  @include respond(phone) {
    flex-direction: column;
    padding: 2rem;
    text-align: left;
  }

  .mission-column {
    display: flex;
    flex-direction: column;
    flex: 1;

    img {
      padding: 0 2rem 0 0;

      @include respond(phone) {
        padding: 0;
      }

      width: 100%;
    }

    &:first-child {
      img {
        padding: 0 2rem 0 0;

        @include respond(tab-port) {
          padding: 0;
        }
      }
    }

    h2 {
      margin: 2rem 0;
      color: $color-black;
      text-transform: uppercase;
      font-family: RalewayBold;

      @include respond(phone) {
        margin: 1rem 0 0 0;
      }
    }

    p {
      font-size: 1.5rem;
      width: 70%;
      font-family: RalewayMedium;

      @include respond(phone) {
        width: 100%;
      }

      line-height: normal;
    }
  }

  p {
    font-size: 2rem;
    color: $color-black;
    line-height: normal;
    flex: 1;
    padding: 0 2rem 0 0;

    @include respond(phone) {
      width: 100%;
      padding: 2rem 0;
      margin-bottom: 2rem;
    }
  }
}