// .heading-primary {
//   color: $color-white;
//   text-transform: uppercase;
//   backface-visibility: hidden;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   position: relative;

//   &--name {
//     font-size: 10rem;
//     font-weight: 800;
//     width: 100%;

//     @include respond(tab-land) {
//       width: 80vw;
//     }

//     @include respond(tab-port) {
//       width: 100vw;
//     }

//     animation: moveInRight 0.7s ease-in-out;

//     @include respond(phone) {
//       height: 8rem;
//       width: 35rem;
//       font-size: 5rem;
//     }
//   }

//   // &--sub-bottom {
//   //     // display: block;
//   //     position: absolute;
//   //     bottom: -30%;
//   //     right: -8%;
//   //     // float: right;

//   //     font-size: 1.6rem;
//   //     letter-spacing: 0.1rem;
//   //     animation: moveInLeft 0.7s ease-in-out;
//   //     align-self: flex-end;
//   //     margin: 1rem 0;

//   //     @include respond(tab-land) {
//   //         bottom: -45%;
//   //         right: 1%;
//   //     }

//   //     @include respond(tab-port) {
//   //         font-size: 2rem;
//   //     }

//   //     @include respond(phone) {
//   //         font-size: 1.2rem;
//   //         // letter-spacing: 0;
//   //     }

//   // }
// }

// .header {
//   display: flex;
//   flex-direction: row;

//   @include respond(tab-port) {
//     flex-direction: column;
//   }

//   justify-content: center;
//   // text-align: flex;
//   position: relative;
//   height: 96vh;
//   width: 100%;

//   .landing-image-container {
//     position: relative;
//     width: 100%;
//     height: 100%;

//     .landing-image {
//       height: 100%;
//       width: 100%;
//       object-fit: contain;
//       object-position: center;
//       // transform: scale(1, 1);
//     }

//     .landing-text-box {
//       border: 1px solid red;
//       position: absolute;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       width: 80%;

//       img {
//         width: 80%;
//         align-self: center;
//       }

//       ul {
//         display: flex;
//         justify-content: space-around;
//         // align-items: center;

//         li {
//           // display: inline-block;
//           padding: 0 3rem;
//           color: $color-white;
//           font-size: 2rem;

//           p {
//             font-size: 3rem;
//           }

//           &:first-child {
//             list-style: none;
//           }
//         }
//       }
//     }
//   }

//   // width: 97vw;
//   &-part-1 {
//     flex: 1;
//     background-size: cover;
//     background-position: right;
//     background-image: url(../../static/images/experiences.jpg);
//   }

//   &-part-2 {
//     background-size: cover;
//     background-position: center;
//     flex: 1;
//     // height: 100%;
//     // background-image: url(../../static/images/landing.png);
//   }

//   &-part-3 {
//     background-size: cover;
//     background-position: right;
//     flex: 1;
//     // background-image: url(../../static/images/sheVentures-India.jpg);
//   }

//   &-part-heading {
//     height: 100%;
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
//     font-size: 10rem;
//     padding: 3rem 0;
//     color: #fff;
//   }

//   background-size: cover;
//   background-position: top;

//   &__text-box {
//     font-family: RalewayBold;
//     font-weight: 1000;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     img {
//       width: 60rem;

//       @include respond(tab-land) {
//         width: 55rem;
//       }

//       @include respond(tab-port) {
//         width: 45rem;
//       }

//       @include respond(phone) {
//         width: 33rem;
//       }
//     }

//     h1 {
//       align-self: flex-end;
//       position: absolute;
//       right: 2%;
//       bottom: 50%;
//       // @include respond(tab-land) {
//       //     width: 55rem;
//       // }
//       // @include respond(tab-port) {
//       //     width: 45rem;
//       // }
//       // @include respond(phone) {
//       //     width: 30rem;
//       // }

//       font-size: 4rem;
//     }
//   }

//   &__footer-img-container {
//     align-items: center;
//     text-align: center;
//     position: absolute;
//     border: 1px solid red;
//     bottom: 0;
//     left: 0;
//     right: 0;
//   }

//   &__footer-img {
//     margin-bottom: -1rem;
//     height: 7rem;
//   }

//   &__about-us {
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     height: 90vh;
//     // width: 97vw;
//     background-image: url(../../static/images/experiences_3.jpg);
//     background-size: cover;
//     background-position: bottom;
//     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

//     &-text-box {
//       font-family: RalewayBold;
//       font-weight: 1000;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }
// }

.heading-primary {
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;

  // &--sub-top {
  //     position: absolute;
  //     top: 15%;
  //     right: -2%;
  //     font-size: 1.6rem;

  //     @include respond(tab-land) {
  //         right: -2%;
  //     }

  //     @include respond(tab-port) {
  //         right: 3%;
  //         font-size: 2rem;
  //     }

  //     letter-spacing: 0.1rem;
  //     animation: moveInLeft 0.7s ease-in-out;

  //     @include respond(phone) {
  //         top: 5%;
  //         right: 4%;
  //         font-size: 1.2rem;
  //         letter-spacing: 0;
  //     }
  // }

  &--name {
    font-size: 10rem;
    font-weight: 800;
    width: 100%;

    @include respond(tab-land) {
      width: 80vw;
    }

    @include respond(tab-port) {
      width: 100vw;
    }

    animation: moveInRight 0.7s ease-in-out;

    @include respond(phone) {
      height: 8rem;
      width: 35rem;
      font-size: 5rem;
    }
  }

  // &--sub-bottom {
  //     // display: block;
  //     position: absolute;
  //     bottom: -30%;
  //     right: -8%;
  //     // float: right;

  //     font-size: 1.6rem;
  //     letter-spacing: 0.1rem;
  //     animation: moveInLeft 0.7s ease-in-out;
  //     align-self: flex-end;
  //     margin: 1rem 0;

  //     @include respond(tab-land) {
  //         bottom: -45%;
  //         right: 1%;
  //     }

  //     @include respond(tab-port) {
  //         font-size: 2rem;
  //     }

  //     @include respond(phone) {
  //         font-size: 1.2rem;
  //         // letter-spacing: 0;
  //     }

  // }
}

.header {
  display: flex;
  flex-direction: row;

  @include respond(tab-port) {
    flex-direction: column;
  }

  justify-content: center;
  // text-align: flex;
  position: relative;
  height: 96vh;
  width: 100%;

  // width: 97vw;
  &-part-1 {
    flex: 1;
    background-size: cover;
    background-position: right;
    background-image: url(../../static/images/experiences.jpg);
  }

  &-part-2 {
    background-size: cover;
    background-position: center;
    flex: 1;
    background-image: url(../../static/images/landing/landing_latest-min.jpg);
  }

  &-part-3 {
    background-size: cover;
    background-position: right;
    flex: 1;
    // background-image: url(../../static/images/sheVentures-India.jpg);
  }

  &-part-heading {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 10rem;
    padding: 3rem 0;
    color: #fff;
  }

  background-size: cover;
  background-position: top;

  &__text-box {
    font-family: RalewayBold;
    font-weight: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ul {
      display: flex;
      justify-content: space-around;
      align-self: center;
      text-align: center;
      width: 70%;

      @include respond(tab-land) {
        width: 100%;
      }

      @include respond(phone) {
        width: 90%;
      }

      // border: 1px solid red;

      li {
        // padding: 12rem 0;
        // margin: 2rem 0;
        font-size: 1.5rem;
        color: $color-white;

        p {
          font-family: TrebuchetBold;
          // margin: 0;
          font-size: 3.5rem;

          @include respond(tab-land) {
            font-size: 3rem;
          }

          @include respond(tab-port) {
            font-size: 2.5rem;
          }

          @include respond(phone) {
            font-size: 2rem;
          }
        }

        &:first-child {
          list-style: none;
        }
      }
    }

    img {
      width: 80rem;

      @include respond(tab-land) {
        width: 60rem;
      }

      @include respond(tab-port) {
        width: 45rem;
      }

      @include respond(phone) {
        align-self: center;
        width: 35rem;
      }
    }

    h1 {
      align-self: flex-end;
      position: absolute;
      right: 2%;
      bottom: 50%;
      // @include respond(tab-land) {
      //     width: 55rem;
      // }
      // @include respond(tab-port) {
      //     width: 45rem;
      // }
      // @include respond(phone) {
      //     width: 30rem;
      // }

      font-size: 4rem;
    }
  }

  &__footer-img-container {
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__footer-img {
    margin-bottom: -1rem;
    height: 7rem;
  }

  &__about-us {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 90vh;
    // width: 97vw;
    background-image: url(../../static/images/experiences_3.jpg);
    background-size: cover;
    background-position: bottom;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

    &-text-box {
      font-family: RalewayBold;
      font-weight: 1000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
