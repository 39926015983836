@font-face {
    font-family: "FuturaBook";
    src: local("FuturaBook"),
        url("../../fonts/FuturaBookfont.ttf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "FuturaHeavy";
    src: local("FuturaHeavy"),
        url("../../fonts/FuturaHeavyfont.ttf") format("opentype");
}

@font-face {
    font-family: "RalewayBold";
    src: local("RalewayBold"),
        url("../../fonts/Raleway-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "RalewayMedium";
    src: local("RalewayMedium"),
        url("../../fonts/Raleway-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "RalewayRegular";
    src: local("RalewayRegular"),
        url("../../fonts/Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "TrebuchetRegular";
    src: local("TrebuchetRegular"),
        url("../../fonts/TrebuchetMS.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "TrebuchetBold";
    src: local("TrebuchetBold"),
        url("../../fonts/TrebuchetMSBold.ttf") format("truetype");
}