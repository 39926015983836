@import "../abstract/mixins";
@import "../base/animations";

.ventures-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // max-height: 70vh;

  @include respond(tab-port) {
    flex-direction: column-reverse;
  }

  margin-top: 3rem;

  ul {
    list-style: none;
    text-align: right;

    @include respond(phone) {
      margin-right: auto;
      text-align: left;
    }

    text-transform: uppercase;
    // font-family: TrebuchetBold;
  }

  &-left {
    flex: 5 5 50%;
    min-height: 100%;

    @include respond(phone) {
      padding: 0 2rem;
    }

    h1 {
      text-transform: none;
      padding: 0 3rem;

      @include respond(tab-land) {
        padding: 0;
      }

      margin-bottom: 0.5rem;
      font-size: 3.5rem;
    }

    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-upper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include respond(tab-port) {
        flex: 1;
        width: 100%;
      }

      &-item {
        padding: 0 3rem;
        line-height: normal;

        @include respond(tab-land) {
          border: none;
          padding: 0 1rem;
          font-size: 1.6rem;
        }

        @include respond(tab-port) {
          font-size: 1.3rem;
        }

        @include respond(phone) {
          padding: 0;
          font-size: 1.2rem;
        }

        list-style: none;

        .ventures-details-wrapper {
          min-height: 38vh;
        }

        h3 {
          font-weight: 1000;
          text-transform: uppercase;
          font-size: 1.5rem;
        }

        p {
          font-family: RalewayMedium;
          font-size: 1.5rem;

          @include respond(tab-land) {
            line-height: 2.5rem;
            font-size: 2rem;
          }

          @include respond(tab-port) {
            font-size: 1.2rem;
          }

          @include respond(phone) {
            font-size: 1.8rem;
          }

          margin: 1rem 0rem;
        }
      }
    }

    &-lower {
      flex: 5 5 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &-r1 {
        display: flex;
        flex-direction: row;

        @include respond(phone) {
          flex-direction: column;
          align-self: flex-start;
          justify-content: left;
        }

        justify-content: space-between;
        align-items: center;

        &-img {
          margin: 3rem;
          // height: 5rem;
        }

        &-right-button {
          height: 3.5rem;
          width: 3.5rem;
          // padding: 3rem;
          margin-right: 2rem;

          .right-button {
            margin-top: 3.5rem;

            & {
              width: 3rem;
              height: 3px;
              background-color: #333;
              display: inline-block;
            }

            &::before,
            &::after {
              content: "";
              width: 3rem;
              height: 3px;
              background-color: #333;
              transform: rotate(45deg);
            }
          }
        }

        .arrow-container {
          margin-left: 3rem;

          @include respond(phone) {
            margin-left: 0;
          }
        }

        &-h1 {
          // height: 10rem;
          font-family: TrebuchetBold;
          font-size: 5rem;

          font-weight: 800;
          color: #d493a1;
          text-transform: uppercase;
          margin-left: 3rem;

          @include respond(tab-land) {
            margin-left: 0;
            font-size: 4.5rem;
          }

          @include respond(tab-port) {
            font-size: 3rem;
          }

          @include respond(phone) {
            font-size: 2rem;
          }

          // line-height: 1;
          overflow: hidden;

          @include respond(phone) {
          }
        }

        &-list {
          font-size: 1rem;
          margin-right: 3rem;
          line-height: 1.2rem;

          @include respond(phone) {
            text-align: left;
            // margin-right: 1rem;
          }

          // align-self: center;
        }
      }
    }

    .ventures-explore-button {
      border-radius: 0.3rem;
      margin-left: 3rem;
      text-align: center;
      border: none;
      color: #fff;
      text-transform: uppercase;
      // height: 2rem;
      width: 25%;
      padding: 1rem;
      background-color: #666;
      font-size: 1.2rem;
    }
  }

  &-right {
    flex: 5 5 50%;
    background-size: cover;
    background-position: center;
    transition: all ease 0.2s;

    @include respond(tab-port) {
      // height: 70vh;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      background-position: center;
    }

    @include respond(phone) {
      height: 100vh;
    }

    &-img {
      width: 100%;
      // animation: slowZoom 20s ease-in-out infinite; /* Adjust the duration as needed */
      animation: moveSlow 20s ease-in-out infinite;
      transform-origin: center center;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2s;
      background-size: cover;
    }
  }
}
