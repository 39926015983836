@import "../abstract/variables";
@import "../abstract/mixins";

.experiences {
    z-index: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 70vh;
    margin-top: 3rem;



    &-heading {

        align-self: center;
        position: absolute;
        bottom: 50%;

        // left: 6%;
        // width: 25%;

        h1 {
            // font-family: "bookeyed-martin";

            // font-family: TrebuchetRegular;
            color: #fff;
            text-transform: uppercase;
            backface-visibility: hidden;
            font-size: 7rem;
            line-height: 5rem;

            @include respond(tab-port) {
                font-size: 6rem;
                text-align: center;
            }
            @include respond(phone) {
                text-align: center;
                font-size: 5rem;
            }
            font-weight: 800;
            // letter-spacing: 0.5rem;
        }
    }


}

// .testimonials-container {
//     z-index: 1;
//     display: flex;
//     justify-content: space-around;
//     flex-direction: row;
//     // width: 80%;

//     .testimonial {
//         display: flex;
//         flex-direction: column;
//         width: 20%;
//         position: relative;
//         padding: 3rem;
//         // margin: 2rem;

//         background-color: #b3c8d3;

//     }
// }


.embla {
    // max-width: 48rem;
    margin: auto;
    --slide-height: 70vh;
    --slide-spacing: 1rem;
    --slide-size: 100%;
}

.embla__viewport {
    overflow: hidden;
    position: relative;
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__controls {
    display: flex;
    position: absolute;
    bottom: 10%;
    left: 45%;

    @include respond(tab-port) {
        left: 40%;
    }

    @include respond(phone) {
        left: 38%;
    }
    // right: 50%;
}


.embla__dots {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-end;
    // align-items: center;
    margin: 1rem;
}

.embla__dot {
    -webkit-tap-highlight-color: rgba(200, 150, 16, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.5rem;
    height: 2.5rem;
    
    @include respond(tab-port) {
        width: 2rem;
        height: 2rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem (white);
    width: 2.5rem;
    height: 2.5rem;
    @include respond(tab-port) {
        width: 2rem;
        height: 2rem;
    }
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem (white);
    background-color: $color-pink;
}