@import "../abstract/variables";
@import "../abstract/mixins";

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  height: 10vh;
  width: calc(100% - 12rem);
  top: 6rem;

  @include respond(tab-land) {
    width: calc(100% - 10rem);
  }

  @include respond(tab-port) {
    width: calc(100% - 9rem);
  }

  @include respond(phone) {
    width: calc(100% - 8rem);
    top: 1rem;
    left: 1rem;
  }

  .nav-logo-box {
    z-index: 103;
    position: relative;
    cursor: pointer;
  }

  .nav-logo {
    position: fixed;
    top: 6rem;
    left: 6rem;
    height: 7rem;
    // width: 12rem;
    margin: 0 1rem;
    // padding: 3rem;

    transition: all ease-in 0.4s;

    @include respond(tab-land) {
      height: 4.5rem;
    }

    @include respond(tab-port) {
      height: 4rem;
    }

    @include respond(phone) {
      // width: 7rem;
      height: 5rem;
      top: 1rem;
      left: 0;
    }
  }

  .navigation {
    &-checkbox {
      display: none;
    }

    &-button {
      background-color: $color-white;
      height: 7rem;
      width: 7rem;
      position: fixed;
      top: 6rem;
      right: 6rem;
      z-index: 103;

      @include respond(tab-land) {
        top: 5rem;
        right: 5rem;
        height: 6rem;
        width: 6rem;
      }

      @include respond(tab-port) {
        top: 3rem;
        right: 3rem;
        height: 5rem;
        width: 5rem;
      }

      @include respond(phone) {
        top: 1rem;
        right: 1rem;
      }

      border-radius: 50%;
      box-shadow: 0 1rem 3rem rgba($color-black, 0.1);
      text-align: center;
      cursor: pointer;
    }

    .navigation-button:hover .navigation-icon::before {
      top: -1.1rem;
    }

    .navigation-button:hover .navigation-icon::after {
      top: 1.1rem;
    }

    &-icon {
      position: relative;
      margin-top: 3.5rem;

      @include respond(tab-land) {
        margin-top: 3rem;
      }

      @include respond(tab-port) {
        margin-top: 2.5rem;
      }

      @include respond(phone) {
        margin-top: 2.5rem;
      }

      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;

        @include respond(phone) {
          height: 2px;
          width: 2rem;
        }

        background-color: #333;
        display: inline-block;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all 0.2s;
      }

      &::before {
        top: -0.8rem;
      }

      &::after {
        top: 0.8rem;
      }
    }

    &-background {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      position: fixed;
      top: 6.5rem;
      right: 6.5rem;

      @include respond(tab-land) {
        top: 5.5rem;
        right: 5.5rem;
        height: 5rem;
        width: 5rem;
      }

      @include respond(tab-port) {
        top: 3.5rem;
        right: 3.5rem;
        height: 4rem;
        width: 4rem;
      }

      @include respond(phone) {
        top: 1.5rem;
        right: 1.5rem;
      }

      z-index: 102;
      transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
      background-color: #97789b;
    }

    &-nav {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 102;
      opacity: 0;
      width: 0;
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .hide {
      position: relative;
    }

    &-list {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      list-style: none;
      text-align: center;

      @include respond(phone) {
        text-align: center;
      }

      &-sub {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0%;
        left: 100%;
        text-align: center;
        transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        @include respond(phone) {
          position: relative;
          top: 0%;
          left: 0%;
        }

        .navigation-item {
          width: 120%;

          @include respond(phone) {
            width: unset;
          }

          transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        .navigation-item::before {
          content: "";
          position: absolute;
          width: 120%;
          z-index: -1000;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &-item {
      margin: 1rem;
    }

    &-link {
      // width: 120%;

      &:link,
      &:visited {
        display: inline-block;
        font-size: 3rem;

        @include respond(tab-land) {
          font-size: 2.5rem;
        }

        @include respond(tab-port) {
          font-size: 2.3rem;
        }

        @include respond(phone) {
          font-size: 1.5rem;
        }

        font-weight: 300;
        padding: 1rem 2rem;
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;
        background-image: linear-gradient(120deg,
          transparent 0%,
          transparent 50%,
          $color-primary 50%);
        background-size: 220%;
        transition: all 0.4s;

        span {
          // margin-right: 1.5rem;
          display: inline-block;
        }
      }

      &:hover,
      &:active {
        background-position: 100%;
        color: $color-white;
        transform: translateX(1rem);
      }
    }

    .navigation-checkbox:checked~.navigation-background {
      transform: scale(80);
    }

    .navigation-checkbox:checked~.navigation-nav {
      opacity: 1;
      width: 100%;
    }

    .navigation-checkbox:checked+.navigation-button .navigation-icon {
      background-color: transparent;
    }

    .navigation-checkbox:checked+.navigation-button .navigation-icon::before {
      transform: rotate(135deg);
      top: 0;
    }

    .navigation-checkbox:checked+.navigation-button .navigation-icon::after {
      transform: rotate(-135deg);
      top: 0;
    }
  }

  .wander-expanded-nav {
    width: 100%;
  }
}

.nav__text-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: $color-white;
  width: 40%;
  position: absolute;
  // font-weight: 800;
  // background-color: red;
  top: 3rem;

  .nav__link {
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}