@import "../abstract/variables";
@import "../base/animations";
@import "../abstract/mixins";
@import "../abstract/fonts";

*,
*::before,
x::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  //This defines what 1rem is. 62.5% of 16px
  font-size: 62.5%; //1 rem = 10px, 10/16 = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    font-size: 50%; // 1 rem = 8px, 8/16 = 50%
  }

  @include respond(desktop) {
    font-size: 65%; // 1 rem = 12px, 12/16 = 72.5%
  }
}

body {
  padding: 3rem;

  @include respond(tab-port) {
    padding: 0;
  }
}

.button-primary {
  border-radius: 0.3rem;
  text-align: center;
  border: none;
  color: #fff;
  text-transform: uppercase;
  width: 18rem;
  height: 4rem;
  padding: 1rem;
  background-color: $color-primary;
  font-weight: 1000;
  font-size: 1.2rem;

  @include respond(tab-land) {
    font-size: 1.1rem;
  }

  @include respond(tab-port) {
    height: 3.5rem;
    font-size: 1rem;
  }

  @include respond(phone) {
    height: 3rem;
    font-size: 0.8rem;
  }
}

.button-pinkish {
  border-radius: 0.3rem;
  text-align: center;
  border: none;
  color: #fff;
  text-transform: uppercase;
  width: 18rem;
  padding: 1rem;
  background-color: #97789b;
  font-weight: 1000;
  font-size: 1.2rem;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.button-greyish {
  display: inline-block;

  animation-fill-mode: backwards;
  animation: moveInBottom 0.2s ease-out;
  margin-top: 3rem;
  border-radius: 0.3rem;
  text-align: center;
  border: none;
  color: #fff;
  text-transform: uppercase;
  width: 18rem;
  padding: 1rem;
  background-color: #666;
  font-size: 1.2rem;
  transition: ease 0.2s;
}

.button-greyish:link,
.button-pinkish:link,
.button-primary:link,
.button-greyish:visited,
.button-pinkish:visited,
.button-primary:visited {
  transition: all 0.2s;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.button-pinkish:hover,
.button-greyish:hover,
.button-primary:hover {
  transition: all 0.2s;
  font-weight: 1000;
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.card:hover {
  color: #fff;
  // cursor: pointer;
  transition: all 0.3s;
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  background-color: $color-pink;
}

.button-pinkish:active,
.button-greyish:active,
.button-primary:active,
.card:active {
  transform: translateY(1px);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.button-greyish::after,
.button-pinkish::after,
.button-primary::after,
.card::after {
  // transition: all 0.4s;
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 0.3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.button-greyish:hover::after {
  transition: all 0.3s;
  transform: scaleX(1.2) scaleY(1.4);
  background-color: #666;
  opacity: 0;
}

.button-pinkish:hover::after {
  transition: all 0.3s;
  transform: scaleX(1.2) scaleY(1.4);
  background-color: #97789b;
  opacity: 0;
}

.button-primary:hover::after {
  transition: all 0.3s;
  transform: scaleX(1.2) scaleY(1.4);
  background-color: $color-primary;
  opacity: 0;
}

.mini-card {
  margin-top: 2rem;
  width: 30rem;
  height: 14rem;
  display: flex;
  background-position: center;
  align-items: center;
  background-size: cover;
  border-radius: 1rem;
  filter: blur(0.1rem);
  -webkit-filter: blur(0.1rem);

  &-icon {
    width: 5rem;
  }

  &-heading {
    z-index: 1;
    font-family: TrebuchetBold;
    // position: absolute;
    // top: 1;
    // right: 3rem;
    color: #fff;
    font-size: 1.5rem;
    text-transform: uppercase;
    // transform: translate(-50%, -50%);
  }

  &-description {
    background: rgba(200, 185, 170, 0.9);
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;

    // margin: 0 auto;;
    // display: flex;
    // align-items: center;
    // text-align: center;

    // padding: 2rem;
  }
}

.mini-card-row {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 2rem;
  text-align: center;
}

.it-container {
  display: flex;
  justify-content: space-around;

  .it-heading {
    font-size: 5rem;
    color: #666;
  }
}

.input-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  // border: 0.7px solid rgba(119, 119, 119, 0.4);
  height: 7rem;
  width: 50%;
  text-transform: uppercase;
  border-radius: 0.5rem;
  margin-top: 1rem;
  transition: all 0.2s;

  input {
    margin-top: 1rem;
    outline-width: 0;
    border: none;
    border-bottom: 1px solid rgba(119, 119, 119, 0.4);
    width: 100%;
  }

  p,
  input::placeholder {
    font-family: RalewayBold;
    color: $color-black;
  }

  // p {
  //     font-size: 1.2rem;
  //     color: #000;
  //     font-family: RalewayMedium;
  // }

  &-select {
    margin-top: 1rem;
    outline-width: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(119, 119, 119, 0.4);

    &:active::after {
      // outline: none;
    }

    &-option {
      width: 100%;
      // outline: none;
    }
  }

  textarea {
    margin-top: 0.5rem;
    outline-width: 0;
    border: none;
    resize: none;
    line-height: 2em;

    @include respond(phone) {
      line-height: 3em;
    }
    background: repeating-linear-gradient(white, white 1.8em, #999 2em);
    font-family: inherit;
    color: #333;
    // border-bottom: 1px solid rgba(119, 119, 119, 0.4);
  }

  &:focus-within {
    transition: all 0.2s;
    transform: translateY(-0.5rem);
  }

  &:not(:focus-within) {
    transition: all 0.2s;
    transform: translateY(0);
  }
}

.card {
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
  width: 22vw;
  height: 40vh;

  @include respond(tab-land) {
    width: 25vw;
    height: 30vh;
  }

  @include respond(phone) {
    width: 100%;
    // margin: 1rem;
  }

  border-radius: 0.5rem;
  overflow: hidden;
  transition: all ease 0.2s;
  background-color: rgba(202, 184, 167);

  &-upper {
    flex: 6 6 60%;

    @include respond(tab-land) {
      flex: 5 5 50%;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h2 {
      font-family: RalewayBold;
      width: 50%;
      font-size: 1.7rem;

      @include respond(tab-land) {
        font-size: 2.3rem;
      }

      text-transform: uppercase;
      padding: 0 0 3rem 3rem;
      color: $color-white;
    }
  }

  &-lower {
    flex: 4 4 40%;

    @include respond(tab-land) {
      flex: 5 5 50%;
    }

    line-clamp: 3;

    p {
      padding: 2rem 0 2rem 3rem;
      color: $color-white;
      font-family: RalewayMedium;
      font-size: 1.3rem;

      @include respond(tab-land) {
        line-height: 2.5rem;
        padding: 0.5rem;
        font-size: 2rem;
      }
    }
  }

  &-heading {
    // position: absolute;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    top: 50%;
    right: 50%;
    color: white;
    transform: translate(50%, -50%);
  }

  // &:last-child {
  //     margin-right: 0;
  // }
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  opacity: 0;

  .mini-card-description {
    p {
      font-size: 1.2rem;
    }
  }
}

// .animate-card

.card:hover {
}

.card:hover .card-image img {
  transform: scale(1.1);

  /* Slight zoom effect on hover */
}

.icon {
  flex: 0.3;
  display: flex;
  align-self: center;
}

.icon img {
  width: 5rem;
  margin: 0 auto;
  /* Set your desired icon size */
  height: 5rem;
}
