@import "../abstract/mixins";
@import "../abstract/variables";

.cu-heading {
  font-size: 8rem;

  @include respond(tab-land) {
    font-size: 6.75;
  }

  @include respond(tab-port) {
    font-size: 6.5rem;
  }

  @include respond(phone) {
    font-size: 5.75rem;
  }
}

.cu-container-part-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70vh;

  // height: 80vh;

  @include respond(phone) {
    height: unset;
    flex-direction: column;

    &:first-child {
      border: 10px solid;
    }

    padding: 2rem;
  }

  &-map-container {
    flex: 0.5;
    max-height: 100%;
    background-position: top;
    background-size: cover;
    // height: 100vh;

    @include respond(phone) {
      flex: none;
      height: 80vh;
      margin-top: -2.5rem;
      background-size: cover;
    }

    // height: 100vh;
  }

  &-details {
    flex: 0.5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 100%;

    &-upper {
      padding: 0 2rem;

      h1 {
        margin-left: 0;
        margin-top: 2rem;
      }

      @include respond(phone) {
        padding: 0;
      }

      p {
        font-family: RalewayMedium;
        color: $color-black;
        font-size: 2rem;
        line-height: normal;

        @include respond(tab-land) {
          font-size: 1.25rem;
        }

        @include respond(tab-port) {
          font-size: 1.2rem;
        }

        @include respond(phone) {
          margin-top: 2.5rem;
          font-size: 1.5rem;
        }
      }

      ul {
        list-style: none;

        li,
        & {
          color: $color-black;
          font-family: RalewayMedium;
          font-size: 1.5rem;
          margin-top: 1.5rem;

          b {
            font-family: RalewayBold;
          }

          @include respond(tab-land) {
            font-size: 1.25rem;
          }

          @include respond(tab-port) {
            font-size: 1.2rem;
          }

          @include respond(phone) {
            font-size: 1.4rem;
          }
        }
      }
    }

    &-sub {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem 1rem 2rem;
      font-size: 1rem;
      font-weight: bold;

      ul {
        list-style: none;

        @include respond(tab-land) {
          font-size: 0.9rem;
        }

        @include respond(tab-port) {
          font-size: 0.8rem;
        }

        @include respond(phone) {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.cu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  // height: 60vh;
  margin: 4rem 0;

  @include respond(tab-land) {
    height: auto;
  }

  @include respond(tab-port) {
    height: auto;
  }

  @include respond(phone) {
    height: auto;
  }

  //   padding: 3rem;

  // @include respond(tab-land) {
  //   padding: 2rem;
  // }

  // @include respond(tab-port) {
  //   padding: 1.3rem;
  // }

  // @include respond(phone) {
  //   padding: 1rem;
  // }

  .cu-heading-sub-container {
    color: #666;
    width: 48%;

    h1 {
      font-size: 3rem;

      @include respond(tab-land) {
        font-size: 2.8rem;
      }

      @include respond(tab-port) {
        font-size: 2.6rem;
      }

      @include respond(tab-port) {
        font-size: 2.5rem;
      }
    }

    // @include respond(tab-land) {
    //     // width: ;
    // }

    @include respond(tab-port) {
      width: 100%;
    }

    h4 {
      font-size: 1.3rem;

      @include respond(tab-land) {
        font-size: 0.9rem;
      }

      @include respond(tab-port) {
        font-size: 0.8rem;
      }

      @include respond(phone) {
        width: 100%;
        font-size: 1rem;
      }

      // font-family: Raleway;
    }
  }

  .cu-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    // height: 40vh;

    .input-container {
      padding: 1rem 1rem;

      @include respond(phone) {
        padding: 2rem;
      }
    }

    @include respond(phone) {
      flex-direction: column;
    }

    &-left {
      display: flex;
      flex-direction: column;
      // align-items: ce;
      width: 48%;
      flex: 1;

      ul {
        display: flex;
        justify-content: space-between;
        // border: 1px solid;
      }

      @include respond(phone) {
        width: 100%;
      }
    }

    &-right {
      width: 48%;
      display: flex;
      flex: 1;
      flex-direction: column;
      // justify-content: space-between;
      // height: 100%;

      .input-container {
        // padding: 0;

        &:nth-child(2) {
          // border: 1px solid red;
          height: auto;
        }

        // height: 10vh;
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }

  .request-input {
    margin: 1rem;
    font-size: 1.5rem;
    border: none;
    border-bottom: 1px solid rgba(119, 119, 119, 0.4);
  }

  .cu-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;

    &-c1 {
      width: 48%;
      display: flex;
      justify-content: space-between;
      color: #000;
      text-transform: uppercase;
      font-family: RalewayMedium;
      font-size: 1rem;

      @include respond(tab-land) {
        font-size: 0.9rem;
      }

      @include respond(tab-port) {
        font-size: 0.8rem;
      }

      @include respond(phone) {
        // width: 100%;
        font-size: 1rem;
      }

      ul {
        list-style: none;

        li {
          font-size: 0.8rem;

          @include respond(phone) {
            // width: 100%;
            font-size: 0.7rem;
          }
        }
      }
    }

    &-c2 {
      width: 48%;
      display: flex;
      justify-content: space-between;
      color: #000;
      font-family: RalewayMedium;
      list-style: none;

      p {
        font-size: 1rem;

        @include respond(tab-land) {
          font-size: 0.9rem;
        }

        @include respond(tab-port) {
          font-size: 0.8rem;
        }

        @include respond(phone) {
          font-size: 0.8rem;
        }
      }
    }

    button {
      font-weight: 1000;
      flex: 0.3;

      @include respond(tab-land) {}

      @include respond(tab-port) {}

      @include respond(phone) {
        // padding: 0rem 0.5rem;
        flex: 1;
      }
    }

    p {
      flex: 0.7;
      margin-left: 10rem;

      @include respond(tab-land) {
        margin-left: 6rem;
      }

      @include respond(tab-port) {
        margin-left: 5rem;
      }

      @include respond(phone) {
        flex: 1;
        font-size: 0.7rem;
        line-height: 0.8rem;
        margin-left: 2rem;
      }

      font-family: RalewayRegular;
      color: #000;
    }
  }
}

.info-send-container {
  display: flex;

  @include respond(tab-port) {
    flex-direction: column-reverse;
  }

  .info-row {
    align-self: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
    flex: 0.5;

    @include respond(tab-port) {
      padding: 2rem;
    }

    &-element {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include respond(phone) {
        width: 100%;
      }

      h2 {
        font-size: 1.7rem;
        font-weight: bolder;
        color: $color-pink;
        font-family: TrebuchetBold;
        margin-top: 1rem;
      }

      p {
        margin-top: 0.4rem;
        font-size: 1.5rem;
        font-family: FuturaHeavy;
        color: $color-grey-dark;
      }

      &:nth-child(2) {
        @include respond(phone) {
          // margin: 0 1rem;
        }

        // padding: 0 1rem;
      }
    }
  }

  .send-request-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    flex: 0.5;
    width: 100%;

    @include respond(phone) {
      padding: 2rem;
      margin-top: -6rem;
      flex-direction: column;
    }

    button {
      flex: 0.3;

      @include respond(phone) {
        align-self: flex-start;
        margin: 2rem 0;
      }
    }

    p {
      padding: 1rem 0;
      width: 70%;

      @include respond(phone) {
        padding: 0;
        width: 100%;
      }

      flex: 0.7;
      font-family: RalewayMedium;
      color: $color-black;
      font-size: 1.2rem;
    }
  }
}