@import "../abstract/mixins";

.banner-container {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    // padding: 20rem;


    h1 {
        color: #fff;
        font-weight: 1000;

        // font-size: 9rem;
        text-transform: uppercase;

        @include respond(phone) {
            letter-spacing: normal;
        }
    }

    img {
        height: 3rem;
    }
}